import axios from "axios";
// import { sha3_256 } from 'js-sha3';
import Cookies from "js-cookie";

export default {

    //USUARIO - COOKIES
    setUserLogged(userLogged) {
        Cookies.set("userLogged", userLogged);
    },
    getUserLogged() {
        return Cookies.get("userLogged");
    },
    deleteUserLogged() {
        Cookies.remove('userLogged');
    },
    
    // METODOS GET Y POST
    // Obtener entidades (Contactos)
    async getContactos() {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/ent_m?api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getObtenerTodosLosContactos(pagina) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/ent_m?page%5Bsize%5D='+pagina+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getObtenerContactosPorNombre(textoPasado) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/ent_m?filter%5Btro_es_clt%5D='+textoPasado+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getMuestreador(idMuestreador) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/usr_m/'+idMuestreador+'?api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getObtenerTomasMuestra() { //Aci es sene filtrar per Pendientes https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue?api_key=Z3bVTfqC4PVq9NLwasHX
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue?filter%5Bpte_mue%5D=1&api_key=Z3bVTfqC4PVq9NLwasHX')
        // ESTA ES LA VELLA .get(' https://lab.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue?filter%5Bpte_mue%5D=1&api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getObtenerTomasMuestraPorMuestreador(idMuestreador) { //Aci es sene filtrar per Pendientes https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue?api_key=Z3bVTfqC4PVq9NLwasHX
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue?filter%5Bmue_m%5D='+idMuestreador+'&api_key=Z3bVTfqC4PVq9NLwasHX')
        // ESTA ES LA VELLA .get('https://lab.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue?filter%5Bpte_mue%5D=1&filter%5Bmue_m%5D='+idMuestreador+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getObtenerPresupuestos(cliente) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/vta_pre_g?filter%5Bclt%5D='+cliente+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getPoblacionCliente(cliente) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/ent_m/'+cliente+'?api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getObtenerTomaMuestra(idMuestra) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue/'+idMuestra+'?api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    async getObtenerTomaMuestraLineas(idMuestra) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_lin?filter%5Btom_mue%5D='+idMuestra+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },    
    async getObtenerParM(numParm) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/par_m/'+numParm+'?api_key=Z3bVTfqC4PVq9NLwasHX')
    },    
    async getObtenerTamanos(numParm) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/par_m/'+numParm+'?api_key=Z3bVTfqC4PVq9NLwasHX')
    },    
    async getTomaMuestraEnv(numParm) {
        return axios
        // http://lab.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_env?filter%5Btom_mue%5D=4&api_key=Z3bVTfqC4PVq9NLwasHX'
        // .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_env?filter%5Bid%5D='+numParm+'&api_key=Z3bVTfqC4PVq9NLwasHX')
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_env?filter%5Btom_mue%5D='+numParm+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },    
    // MODIFICAR TOMA DE MUESTRA
    async postModificarTomaMuestra(
            // BLOQUE UNO - - - - /
            idMostra, fecha, fechaHora, temperatura, codSonda, muestraRefrigeradaSi, muestraRefrigeradaNo, chMarcada, dparMarcada, sinacMarcada,
            // BLOQUE DOS - - - - /
            refCliente, descMuestra, idMuestraPro,
            // BLOQUE TRES - - - - /
            tipoMuestra, tipoMuestraOtro,
            // BLOQUE CUATRO - - - - /
            tomadaPorAmbitec, tomadaPorNombre, horaTomadoMuestra,
            selectedValuesLMC1, selectedValuesLMC2, selectedValuesLMC3, procedimiento,
            tipoMuestreo, laH, codTomaMuestras, verificacionTomaMuestrasCor,
            verificacionTomaMuestrasInc, fechaInicioDia, fechaInicioHora, fechaFinDia, fechaFinHora, localizacionMuestreo,
            // BLOQUE CINCO - - - - -/
            segunPresupuesto, tipoAnaliticaTexto,
            // OBSERVACIONES Y CROQUIS
            observaciones, croquisRecortada,
            // FIRMAS
            firmaCliente, firmaTecnico,
            // GUARDAR O MODIFICAR MUESTRA
            guardarTrueFalse
        ) {
        axios.post('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue/'+idMostra+'?api_key=Z3bVTfqC4PVq9NLwasHX', {
            fecha: fecha, //La fecha 2023-06-13
            hor: fechaHora, //La fecha con hora 2023-06-13T00:00:00.000Z
            tem: temperatura, //La temperatura Tª (ºC)
            cod_son: codSonda, //El codigo de la sonda Codigo
            // ref: muestraRefrigerada, //Muestra Refrigerada (Devuelve un true o false dependiendo de si es SI o NO)
            mue_ref: muestraRefrigeradaSi, //Muestra refrigerada si
            mue_no_ref: muestraRefrigeradaNo, //Muestra refrigerada no
            ch: chMarcada, //CH - devolvemos TRUE si esta checked
            dpar: dparMarcada, //DPAR - devolvemos TRUE si esta checked
            sinac: sinacMarcada, //SINAC - devolvemos TRUE si esta checked
            ref_clt: refCliente, //Referencia del cliente
            des_mue: descMuestra, //Descripcion muestra
            id_mue: idMuestraPro, //ID Muestra Procedencia
            tp_mue_tom_mue: tipoMuestra, //Desplegale Tipo Muestra
            tp_mue_otr: tipoMuestraOtro, //Desplegale Tipo Muestra si la opcion es otro, el texto escrito
            tom_por: tomadaPorAmbitec, //Check, saber si ha sido tomada o no por Ambitec, metemos un true o un false
            tom_por_name: tomadaPorNombre, //Tomada por nombre
            hor_tom_por: horaTomadoMuestra, //La hora en la que han tomado la muestra
            ent_lab: selectedValuesLMC1, // Check de si ha sido marcado 'Entrega en Laboratorio'
            env_men: selectedValuesLMC2, // Check de si ha sido marcado 'Enviada por mensajeria'
            mue_con: selectedValuesLMC3, // Check de si ha sido marcado 'Muestra contradictoria'
            pro: procedimiento, //Procedimiento
            tp_mue: tipoMuestreo, //Tipo muestreo, puntual o compuesto
            tp_mue_hor: laH, //Hora de la H
            cdg_tom_mue: codTomaMuestras, //Codigo Toma Muestras
            ver_tom_cor: verificacionTomaMuestrasCor, //Verificacion toma muestras
            ver_tom_inc: verificacionTomaMuestrasInc, //Verificacion toma muestras
            fch_ini: fechaInicioDia,
            hor_ini: fechaInicioHora,
            fch_fin: fechaFinDia,
            hor_fin: fechaFinHora,
            loc_mue: localizacionMuestreo, //Localizacion del muestreo
            lon: 0,
            lat: 0,
            alt: 0,
            seg_pre: segunPresupuesto, // Segun presupuesto, devuelve true o false
            tp_ana: true,
            tp_ana_name: tipoAnaliticaTexto, //Tipo analitica
            obs: observaciones, // Observaciones
            crq: croquisRecortada, // Croquis dibujo
            fir_clt: firmaCliente, // Firma cliente
            fir_tec_lab: firmaTecnico, // Firma tecnico laboratorio
            mue: guardarTrueFalse, //Si esta en false es mostrara com a pendent encara
            usr_m: 0
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.log(error);
        });
    },
    async getObtenerEnvasesCreados(idMostra) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_env?filter%5Btom_mue%5D='+idMostra+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },    
    async getObtenerDatosEnvasesCreados(idMostra) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_env/'+idMostra+'?fields=id,env.name,tam_env.name,num_env&api_key=Z3bVTfqC4PVq9NLwasHX')
    },  
    async getObtenerServicios(idMostra) {
        return axios
        .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_ser?filter%5Btom_mue%5D='+idMostra+'&api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    postEliminarEnvase(idEnvase) {
        axios.delete('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_env/'+idEnvase+'?api_key=Z3bVTfqC4PVq9NLwasHX')
    },
    // MODIFICAR TOMA DE MUESTRA
    async postAnadirEnvase(idMostra, index, numEnvase, volumen, tioTrueFalse, otroName) {
        axios.post('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_env?api_key=Z3bVTfqC4PVq9NLwasHX', {
            "tom_mue": idMostra,
            "env": index,
            "otr_env": "string",
            "tam_env": volumen,
            "tio": tioTrueFalse,
            "otr_name": otroName,
            "num_env": numEnvase
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.log(error);
        });
    },
    // ASIGNAR ENVASES CREADOS A TOMA DE MUESTRA LINEAS
    async postAsignarEnvases(idMuestra, idParametro, idEnvase) {
        axios.post('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/tom_mue_lin/'+idParametro+'?api_key=Z3bVTfqC4PVq9NLwasHX', {
            "tom_mue": idMuestra,
            "par_m": idParametro,
            "tom_mue_env": idEnvase
        }, {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(response => {
            console.log(response.data);
        }).catch(error => {
            console.log(error);
        });
    },       
    //METODOS LOGIN, REGISTRO, ETC
    async metodoLogin(email, contra) {
        // const user = { email, contra };

        // var loginCambiado = user.email.replace("@", "%40"); //El correo el @ lo pasamos a %40
        // var passCodificada = sha3_256(user.contra); //Codificamos la contrasenya

        // return axios
        // .get('https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/usr_m?filter%5Busr_pwd%5D='+ loginCambiado +'+%2C'+ passCodificada +'&api_key=Z3bVTfqC4PVq9NLwasHX')

        const loginCambiado = email.replace('@', '%40');
        const url = `https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/usr_m?filter[usr_pwd]=${loginCambiado},${contra}&api_key=Z3bVTfqC4PVq9NLwasHX`;

        try {
            const response = await axios.get(url);
            return response.status === 200 ? response.data : null;
        } catch (error) {
            console.log(`Error de conexión: ${error.message}`);
            return null;
        }

    }
};

// https://vmod.proynet.es/ambitec/ProLab_dat_dat/v1/usr_m?filter%5Busr_pwd%5D=laboratorio%2Cteclab&api_key=Z3bVTfqC4PVq9NLwasHX