<template>
  <!-- LINK ICONS BOOTSTRAP -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">

  <!-- NAVBAR -->
  <div class="text-center p-1 bg-verde text-white" v-show="userLogged">
    <i class="fa-thin fa-hand-wave"></i> Bienvenido de nuevo {{userLogged}} !
  </div>

  <!-- NAV 2023 -->
  <div class="px-3 py-2 bg-verde-ambitec text-white">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
          <img src="@/assets/logo-ambitec.png" alt="Ambitec Laboratorios">
        </a>

        <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
          <li>
            <router-link to="/">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/dashboardwhite.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Escritorio
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/login" @click="logout">
              <a href="#" class="nav-link text-white"  v-if="userLogged">
                <img src="@/assets/log-out.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Log Out
              </a>
            </router-link> 
            <a href="#" class="nav-link text-white" v-if="!userLogged">
              <img src="@/assets/log-in.svg" alt="Dashboard" style="width: 25px;">
              <br>
              Log In
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- NAV 2023 -->

  <!-- ROUTER VIEW - se mostrara cada pagina dependiendo de donde nos encontremos -->
  <router-view/>

</template>

<script>
import auth from '../src/api/auth';
// import { useMyStore } from '@/api/myStore'

export default {
  computed: {
    userLogged() {
      return auth.getUserLogged();
    }
  },
  // METODO
  methods: {
    logout() {
      auth.deleteUserLogged();
    }
  },
  async mounted() {
    
    // // OBTENER ARTICULOS
    // // LO PASAMOS A MINUSCULA PARA FILTRAR MEJOR
    // let myStore = useMyStore();

    // // OBTENER CLIENTES
    // //let contador = 0;
    // for (let index = 0; index < 1000; index++) {
    //   //Comprobamos si la página tiene registros. En el momento que no hayan registros, el for se para)
    //   var respuestaCantidadRegistrosClientes = await auth.getObtenerTodosLosContactos(index);
    //   let quedanRegistrosClientes;
    //   respuestaCantidadRegistrosClientes.data.count == 0 ? quedanRegistrosClientes = false : quedanRegistrosClientes = true;

    //   //console.log(respuestaCantidadRegistrosClientes.data.count);
    //   if (!quedanRegistrosClientes) break;

    //   respuestaCantidadRegistrosClientes.data.ent_m.forEach(art => {
    //     myStore.clientes.push(art);
    //     // articulosStorage.push(art)
    //   });
      
    //   //console.log("Clientes obtenidos - " + contador++);
    // }

    // myStore.estadoClientes = true;
    // console.log("Todos los clientes han sido obtenidos, terminado");
  }

};
</script>

<style>
* {
  font-family: 'Montserrat', sans-serif;
}

#app {
  font-weight: 400;
  text-align: center;
  color: #2c3e50;
}

a {
  color: #008080 !important;
  text-decoration: inherit !important;
  font-weight: 400;
}

a:hover {
  color: #C0C0C0; /* Cambiar aquí el color del texto de los enlaces al pasar el mouse */
}

nav {
  padding: 30px;
}

nav a.router-link-exact-active {
  color: white;
}

/* ICONOS */
::before {
    padding-bottom: 3%;
}

.bg-verde {
  background-color: #7FCC72;
}

.bg-verde-ambitec {
  background-color: #008080;
  border-bottom: 2px solid black;
}

/* El row por defecto mete un espacio en blanco pequeño */
/* .row {
  --bs-gutter-x: inherit;
} */

</style>
