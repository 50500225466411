import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import router from './router'
// IMPORTS DEL AXIOS 👈
import axios from 'axios'
import VueAxios from 'vue-axios'

// IMPORTS DE PINIA
import { createPinia } from 'pinia'

import './registerServiceWorker'


// Create App
const app = createApp(App);

// Use pinia
app.use(createPinia());

// Use Axios
app.use(VueAxios, axios); // FIN AXIOS 👈

createApp(App).use(router).mount('#app');
